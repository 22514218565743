import { useState } from 'react';

import { DropDownProps } from '../../components/Helper/datatypes';
import classes from './DropDown.module.css';

const DropDown = (props: DropDownProps) => {
    const { onSelectedValueChange } = props;
    const { dropDownItems } = props;

    const [selectedOption, setSelectedOption] = useState<string>(dropDownItems[0].displayName);

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        // console.log('handleSelectChange');
        const newSelectedValue: string = event.target.value;
        const newSelectedOption = event.target.selectedOptions[0];
        setSelectedOption(newSelectedValue);
        if (selectedOption) {
            const newSelectedId = newSelectedOption.getAttribute('data-unit-id');
            setSelectedOption(newSelectedValue);
            if (newSelectedId !== null) {
                onSelectedValueChange(newSelectedId);
            }
        }
    };

    return (
        <div className={classes.dropdown}>
            <label htmlFor="dropdown">
                <select id="dropdown" value={selectedOption} onChange={handleSelectChange}>
                    {dropDownItems.map((dropDownItem) => (
                        <option
                            key={dropDownItem.id}
                            value={dropDownItem.displayName}
                            data-unit-id={dropDownItem.id}
                        >
                            {dropDownItem.displayName}
                        </option>
                    ))}
                </select>
            </label>
        </div>
    );
};

export default DropDown;
