import { DRAWER_WIDTH_UNITS } from '@local/web-design-system/dist/styles/constants';
import { getSpacing } from '@local/web-design-system/dist/styles/getSpacing';
import { makeStyles } from 'tss-react/mui';

const calcWidth = (calcPx: number) => `calc(100% - ${calcPx}px)`;

export const useStyles = makeStyles()((theme) => {
    const marginLeft = theme.spacing(DRAWER_WIDTH_UNITS);
    return {
        appLogo: {
            padding: '12px 12px',
            height: '36px',
        },
        logoTitle: {
            padding: '0 40px 0 0',
            height: '36px',
        },
        appContent: {
            marginTop: theme.spacing(8),
            marginLeft: theme.spacing(7),
            minHeight: 'calc(100vh - 92px)',
        },
        appBarShift: {
            [theme.breakpoints.up('sm')]: {
                marginLeft,
                width: calcWidth(getSpacing(theme, DRAWER_WIDTH_UNITS) + 1),
            },
        },
    };
});
