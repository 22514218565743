import { DateTimeProps } from '../../components/Helper/datatypes';
import classes from './DateTimePicker.module.css';

const DateTimePicker = (props: DateTimeProps) => {
    const { fromDateValue } = props;
    const { toDateValue } = props;
    const { onFromDateChange } = props;
    const { onToDateChange } = props;

    return (
        <div className={classes.datetimecontrols}>
            <div className={classes.datetimecontrol}>
                <label htmlFor="from">
                    From
                    <input
                        type="date"
                        id="from"
                        value={fromDateValue}
                        onChange={onFromDateChange}
                    />
                </label>
            </div>
            <div className={classes.datetimecontrol}>
                <label htmlFor="to">
                    To
                    <input type="date" id="to" value={toDateValue} onChange={onToDateChange} />
                </label>
            </div>
        </div>
    );
};

export default DateTimePicker;
