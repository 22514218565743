import {
    LineChartOptions,
    Model,
    ModelBars,
    OneBar,
    BarChartOptions,
    ScatterPoint,
    ScatterData,
    ScatterDatasets,
    LineData,
    LinePlotData,
} from '../../components/Helper/datatypes';
import { valueToColor } from './ColorscaleFunctions';

export function getChartMisfitData(fetchedData: Model[]) {
    const localDataValues: ScatterPoint[] = fetchedData.map((dataPoint) => ({
        x: dataPoint.measurementTime,
        y: dataPoint.misfit,
    }));

    const localScatterDataset: ScatterData = {
        label: 'Scatter Dataset',
        data: localDataValues,
        backgroundColor: 'rgb(0, 0, 0)',
        borderColor: 'rgb(255, 0, 0)',
    };

    const dataForPlot: ScatterDatasets = {
        datasets: [localScatterDataset],
    };

    return dataForPlot;
}

export function getWaterDepthInfo(waterLayer: number, fetchedData: Model[]) {
    const localLabels: string[] = [];
    const localDataset: LineData = {
        label: 'proba',
        data: [],
        fill: false,
        borderColor: 'rgb(0, 0, 255)',
    };

    fetchedData.forEach((dataPoint) => {
        localLabels.push(dataPoint.measurementTime.toString());
        localDataset.data.push(
            parseFloat(dataPoint.depths.split(',').map(Number)[waterLayer - 1].toFixed(2)),
        );
    });

    const dataForPlot: LinePlotData = {
        labels: localLabels,
        datasets: [localDataset],
    };

    return dataForPlot;
}

export function getChartData(option: number, firstModel: Model, fetchedData: Model[]) {
    let layerNumber: number = firstModel.depths.split(',').length + 1;

    const localLabels: string[] = [];
    const localDataset: OneBar[] = [];

    let firstResValues: number[];

    if (option > 0) {
        firstResValues = firstModel.resistivities.split(',').map(Number);
    }

    for (let i = 0; i < layerNumber; i += 1) {
        const newChannel: OneBar = {
            label: `Test${i.toString()}`,
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 0,
            barThickness: 10,
            maxBarThickness: 38,
        };

        localDataset.push(newChannel);
    }

    fetchedData.forEach((dataPoint) => {
        layerNumber = dataPoint.depths.split(',').length + 1;

        const depthArr = dataPoint.depths.split(',').map(Number);

        const arrayLength = depthArr.length;

        let accumulation = 0;
        let tmpValue = 0;
        for (let j = 0; j < arrayLength; j += 1) {
            tmpValue = depthArr[j];
            depthArr[j] -= accumulation;
            accumulation = tmpValue;
        }

        const resistivityValues = dataPoint.resistivities.split(',').map(Number);

        const lastDepth = depthArr[depthArr.length - 1] * 1.5;

        depthArr.push(lastDepth);

        localLabels.push(dataPoint.measurementTime.toString());

        let finalColor: string;

        for (let i = 0; i < layerNumber; i += 1) {
            localDataset[i].data.push(depthArr[i]);

            if (option > 0) {
                finalColor = valueToColor(
                    ((resistivityValues[i] - firstResValues[i]) / firstResValues[i]) * 100,
                );
            } else {
                finalColor = valueToColor(resistivityValues[i]);
            }

            localDataset[i].backgroundColor.push(finalColor);

            localDataset[i].borderColor.push('black');
        }
    });

    const dataForPlot: ModelBars = {
        labels: localLabels,
        datasets: localDataset,
    };

    return dataForPlot;
}

export function getChartLineOptions(chartTitle: string, yTitle: string, keepAspectRatio: boolean) {
    const options: LineChartOptions = {
        layout: {
            padding: {
                top: 20,
            },
        },
        plugins: {
            title: {
                display: true,
                text: chartTitle,
            },
        },
        maintainAspectRatio: keepAspectRatio,
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'dd-MM-yy',
                    },
                },

                ticks: {
                    maxTicksLimit: 5,
                },
            },
            y: {
                type: 'linear',
                title: { display: true, text: yTitle },

                ticks: {
                    maxTicksLimit: 5,
                    callback: (value: number) => value.toFixed(2).toString(),
                },
            },
        },
    };
    return options;
}

export function getChartBarOptions(
    chartTitle: string,
    yTitle: string,
    reverseValue: boolean,
    stratAtZeroValue: boolean,
) {
    const options: BarChartOptions = {
        plugins: {
            title: {
                display: true,
                text: chartTitle,
            },
        },
        scales: {
            x: {
                type: 'time' as const,
                // min: '2024-01-01',
                // max: '2024-03-20',
                stacked: true,
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'dd-MM-yy',
                    },
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 5,
                    // color: 'green',
                    maxRotation: 0,
                    minRotation: 0,
                    align: 'center',
                },
                label: {
                    avoidCollisions: true,
                    minSpacing: 50,
                    edgeLabelPlacement: 'Shift',
                    // format: '%d-%m-%Y',
                },
            },
            y: {
                type: 'linear' as const,
                beginAtZero: stratAtZeroValue,
                reverse: reverseValue,
                stacked: true,
                title: {
                    display: true,
                    text: yTitle,
                },
            },
        },
    };

    return options;
}
