import classes from './Card.module.css';

interface Props {
    hover: boolean;
    children: React.ReactNode;
}

const Card = (props: Props) => {
    const { children } = props;
    const { hover } = props;

    let useClass = classes.cardNoHover;
    if (hover) {
        useClass = classes.card;
    }

    return <div className={useClass}>{children}</div>;
};

export default Card;
