import {
    TemData,
    GateDef,
    GatePointDef,
    ChannelDef,
    Plotdata,
    LineChartOptions,
} from '../../components/Helper/datatypes';

function getGateColor(gateNo: number) {
    switch (gateNo) {
        case 1:
            return 'rgb(0, 0, 0)';
        case 2:
            return 'rgb(0, 0, 0)';
        case 3:
            return 'rgb(0, 0, 0)';
        case 4:
            return 'rgb(255, 51, 0)';
        case 5:
            return 'rgb(0, 255, 255)';
        case 6:
            return 'rgb(0, 0, 255)';
        case 7:
            return 'rgb(255, 153, 0)';
        case 8:
            return 'rgb(153, 0, 204)';
        case 9:
            return 'rgb(0, 153, 153)';
        case 10:
            return 'rgb(255, 204, 255)';
        case 11:
            return 'rgb(255, 255, 0)';
        case 12:
            return 'rgb(102, 255, 51)';
        case 13:
            return 'rgb(255, 51, 204)';
        case 14:
            return 'rgb(255, 51, 0)';
        case 15:
            return 'rgb(0, 255, 255)';
        case 16:
            return 'rgb(0, 0, 255)';
        case 17:
            return 'rgb(255, 153, 0)';
        case 18:
            return 'rgb(153, 0, 204)';
        case 19:
            return 'rgb(0, 153, 153)';
        case 20:
            return 'rgb(255, 204, 255)';
        case 21:
            return 'rgb(255, 255, 0)';
        case 22:
            return 'rgb(102, 255, 51)';
        case 23:
            return 'rgb(255, 51, 204)';

        default:
            return 'rgb(0, 0, 0)';
    }
}

export function getChartData(fetchedData: TemData[]) {
    const channelData2: ChannelDef[] = [];

    fetchedData.forEach((dataPoint) => {
        const channelNo: number = dataPoint.channel;
        const channelObject = channelData2.find((item) => item.channelNumber === channelNo);

        if (channelObject == null) {
            // We didn't find an object with correct channel number - create it.
            const newChannel: ChannelDef = {
                channelNumber: channelNo,
                datasets: [],
            };

            // First we create the 'data' array -

            const dataStrArr = dataPoint.data.split(',');
            let gateNo = 0;
            dataStrArr.forEach((point) => {
                gateNo += 1;

                // Check if the value is Nan - then we skip the point entirely:
                if (point.toLowerCase() !== 'nan' && gateNo > 3) {
                    const newGatePoint: GatePointDef = {
                        x: dataPoint.measurementTime,
                        y: parseFloat(point),
                    };

                    const newGate: GateDef = {
                        gateNumber: gateNo,
                        data: [],
                        backgroundColor: 'rgb(0, 0, 0)',
                        borderColor: getGateColor(gateNo),
                    };

                    newGate.data.push(newGatePoint);

                    newChannel.datasets.push(newGate);
                }
            });
            channelData2.push(newChannel);
        } else {
            // We already have the correct channel object
            const dataStrArr = dataPoint.data.split(',');
            let gateNo = 0;
            dataStrArr.forEach((point) => {
                gateNo += 1;

                // Check if the value is Nan - then we skip the point entirely:
                if (point.toLowerCase() !== 'nan' && gateNo > 3) {
                    const newGatePoint: GatePointDef = {
                        x: dataPoint.measurementTime,
                        y: parseFloat(point),
                    };

                    // Check if proper gate object exists already:
                    const gateObject = channelObject.datasets.find(
                        (gate) => gate.gateNumber === gateNo,
                    );
                    if (gateObject == null) {
                        // The gate object was not found - we create a new one:
                        const newGate: GateDef = {
                            gateNumber: gateNo,
                            data: [],
                            backgroundColor: 'rgb(0, 0, 0)',
                            borderColor: getGateColor(gateNo),
                        };

                        newGate.data.push(newGatePoint);

                        channelObject.datasets.push(newGate);
                    } else {
                        // Gate object already exists, create the gatePoint and add it to gate object
                        gateObject.data.push(newGatePoint);
                    }
                }
            });
        }
    });
    const dataForPlot2: Plotdata = {
        channelData: channelData2,
    };

    return dataForPlot2;
}

export function getChartOptions(iChannelNo: number) {
    const options: LineChartOptions = {
        layout: {
            padding: {
                top: 20,
            },
        },
        plugins: {
            title: {
                display: true,
                text: `Channel ${iChannelNo.toString()}`,
            },
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'dd-MM-yy',
                    },
                },

                ticks: {
                    maxTicksLimit: 5,
                },
            },
            y: {
                type: 'logarithmic',
                title: { display: true, text: 'dB/dt [V/A*m4]' },

                ticks: {
                    maxTicksLimit: 5,
                    callback: (value: number) => value.toExponential(2),
                },
            },
        },
    };
    return options;
}
