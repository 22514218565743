import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { getConfig, ProvideConfig } from '@local/app-config';
import { ErrorBoundary } from '@local/error-logging';
import { MetricsWrapper, trackError } from '@local/metrics';
import { ErrorScreen } from '@local/svgs/dist/pageState/ErrorScreen';
import { darkTheme } from '@local/web-design-system/dist/styles/theme';
import { ThemeProvider } from '@mui/material/styles';
import { initialize, LDClient } from 'launchdarkly-js-client-sdk';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { TssCacheProvider } from 'tss-react';

import App from './App';
import { store } from './store/store';
import { ERROR_BOUNDARY_DETAILS, ERROR_BOUNDARY_MESSAGE } from './strings';

const muiCache = createCache({
    key: 'mui',
    prepend: true,
});

const tssCache = createCache({
    key: 'tss',
});

const errorScreen = () => (
    <ErrorScreen msg={ERROR_BOUNDARY_MESSAGE} details={ERROR_BOUNDARY_DETAILS} />
);

let ldClient: LDClient | null = null;

function LaunchDarklyProviderWrapper() {
    const [loaded, setLoaded] = useState(false);
    const config = getConfig();
    // Only initialise the client if the global client is null - ie it hasn't been loaded before.
    if (ldClient === null) {
        ldClient = initialize(config.launchDarklyClientID, { anonymous: true });
    }

    useEffect(() => {
        if (ldClient !== null) {
            ldClient
                .waitForInitialization()
                .catch((reason) => {
                    trackError(reason, 'Error initialising LD client, rendering defaults');
                })
                .finally(() => setLoaded(true));
        }
    }, []);

    if (loaded) {
        const LDProvider = withLDProvider({ clientSideID: config.launchDarklyClientID, ldClient })(
            App,
        );
        return <LDProvider />;
    }

    return null;
}

// We only send data to sentry and datadog for release builds (NODE_ENV production), not dev builds.
const recordMetrics = process.env.NODE_ENV === 'production';

// TODO document how to create
// Taken from https://sentry.io/settings/seequent-ltd/projects/blockmodelservice-ui/keys/
const sentryDsn = '';

// TODO document how to create
// Taken from https://app.datadoghq.com/rum/application/7264c6d8-a1fc-42ed-ba1f-b6ad0979482c
const datadogConfig = { appId: '', clientToken: '' };

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <ProvideConfig>
            <CacheProvider value={muiCache}>
                <TssCacheProvider value={tssCache}>
                    <ThemeProvider theme={darkTheme}>
                        <ErrorBoundary
                            errorComponent={errorScreen()}
                            sentryDSN={recordMetrics ? sentryDsn : undefined}
                        >
                            <MetricsWrapper
                                datadogConfig={recordMetrics ? datadogConfig : undefined}
                            >
                                <Provider store={store}>
                                    <BrowserRouter>
                                        <LaunchDarklyProviderWrapper />
                                    </BrowserRouter>
                                </Provider>
                            </MetricsWrapper>
                        </ErrorBoundary>
                    </ThemeProvider>
                </TssCacheProvider>
            </CacheProvider>
        </ProvideConfig>
    </React.StrictMode>,
);
