import { ColorscaleDef } from './datatypes';

export const ResisitivityCS: ColorscaleDef = {
    csData: [
        1, 1.01358313333407, 1.0273507681793, 1.04130541064434, 1.0554496008786, 1.06978591353472,
        1.08431695823717, 1.0990453800573, 1.1139738599948, 1.12910511546578, 1.14444190079732,
        1.15998700772894, 1.17574326592071, 1.19171354346834, 1.20790074742528, 1.22430782433188,
        1.24093776075172, 1.25779358381529, 1.27487836177098, 1.29219520454363, 1.30974726430059,
        1.32753773602551, 1.34556985809995, 1.36384691289282, 1.3823722273579, 1.40114917363941,
        1.42018116968587, 1.43947167987224, 1.45902421563056, 1.4788423360891, 1.49892964872026,
        1.51928980999721, 1.53992652605949, 1.56084355338762, 1.5820446994869, 1.60353382358049,
        1.62531483731186, 1.64739170545691, 1.66976844664556, 1.69244913409336, 1.71543789634288,
        1.73873891801521, 1.76235644057174, 1.78629476308618, 1.81055824302712, 1.83515129705125,
        1.86007840180728, 1.88534409475085, 1.91095297497044, 1.93690970402459, 1.96321900679041,
        1.98988567232361, 2.01691455473033, 2.04431057405065, 2.07207871715422, 2.10022403864801,
        2.12875166179637, 2.15766677945367, 2.18697465500947, 2.21668062334669, 2.24679009181264,
        2.27730854120339, 2.30824152676137, 2.33959467918659, 2.37137370566166, 2.40308554324193,
        2.43522145596494, 2.46778711489047, 2.50078826691609, 2.53423073579131, 2.5681204231453,
        2.60246330952838, 2.6372654554674, 2.67253300253523, 2.70827217443461, 2.74448927809643,
        2.78119070479269, 2.81838293126445, 2.85607252086473, 2.89426612471675, 2.9329704828877,
        2.97219242557814, 3.01193887432733, 3.05221684323469, 3.0930334401976, 3.13439586816571,
        3.17631142641207, 3.21878751182124, 3.26183162019462, 3.30545134757324, 3.34965439157828,
        3.39444855276939, 3.43984173602137, 3.48584195191906, 3.53245731817105, 3.57969606104217,
        3.62756651680521, 3.67607713321203, 3.72523647098433, 3.77505320532439, 3.825536127446,
        3.8766941461258, 3.9285362892755, 3.98107170553497, 4.03430966588679, 4.08825956529221,
        4.14293092434921, 4.19833339097249, 4.25447674209614, 4.31137088539894, 4.36902586105278,
        4.42745184349449, 4.4866591432213, 4.54665820861035, 4.60745962776257, 4.66907413037109,
        4.73151258961481, 4.79478602407714, 4.85890559969049, 4.92388263170674, 4.98972858669402,
        5.05645508456025, 5.12407390060375, 5.19259696759117, 5.26203637786334, 5.33240438546918,
        5.40371340832821, 5.47597603042194, 5.5492050040146, 5.62341325190349, 5.70227800904582,
        5.78224879372704, 5.86334111727261, 5.94557070854439, 6.02895351699142, 6.11350571574348,
        6.19924370474814, 6.28618411395168, 6.37434380652468, 6.46373988213288, 6.55438968025381,
        6.64631078354005, 6.73952102122957, 6.83403847260397, 6.92988147049512, 7.0270686048411,
        7.12561872629193, 7.22555094986587, 7.32688465865703, 7.42963950759495, 7.53383542725694,
        7.63949262773386, 7.74663160255008, 7.85527313263849, 7.96543829037121, 8.07714844364684,
        8.19042526003503, 8.30529071097915, 8.42176707605798, 8.53987694730704, 8.65964323360066,
        8.78108916509537, 8.90423829773577, 9.02911451782339, 9.15574204664984, 9.28414544519475,
        9.4143496188897, 9.54637982244898, 9.68026166476797, 9.81602111389039, 9.95368450204508,
        10.0932785307534, 10.2348302760086, 10.378367193527, 10.5239171240739, 10.6715082988636,
        10.8211693450347, 10.9729292912032, 11.1268175730928, 11.2828640392443, 11.4410989568051,
        11.6015530173997, 11.7642573430833, 11.9292434923776, 12.0965434663924, 12.2661897150326,
        12.4382151432919, 12.6126531176356, 12.7895374724723, 12.9689025167162, 13.1507830404421,
        13.3352143216332, 13.5135430893244, 13.694256606793, 13.8773867647518, 14.0629658803807,
        14.25102670303, 14.4416024199993, 14.6347266623945, 14.8304335110627, 15.0287575026061,
        15.2297336354771, 15.4333973761545, 15.639784665402, 15.8489319246111, 16.0608760622281,
        16.2756544802675, 16.4933050809125, 16.7138662732033, 16.9373769798156, 17.163876643929,
        17.3934052361878, 17.6260032617546, 17.8617117674581, 18.100572349037, 18.3426271584802,
        18.5879189114656, 18.836490894898, 19.088386974548, 19.3436516027933, 19.6023298264628,
        19.8644672947866, 20.1301102674513, 20.3993056227638, 20.6721008659239, 20.9485441374075,
        21.228684221462, 21.5125705547154, 21.8002532349005, 22.0917830296956, 22.3872113856834,
        22.6865904374301, 22.9899730166854, 23.2974126617061, 23.6089636267036, 23.9246808914186,
        24.2446201708233, 24.5688379249533, 24.8973913688715, 25.2303384827646, 25.5677380221753,
        25.9096495283703, 26.2561333388486, 26.6072505979881, 26.963063267837, 27.3236341390476,
        27.6890268419571, 28.0593058578168, 28.4345365301709, 28.8147850763879, 29.2001185993461,
        29.590605099275, 29.9863134857557, 30.3873135898814, 30.7936761765806, 31.2054729571049,
        31.6227766016838, 32.0523129926579, 32.4876838337023, 32.9289683749305, 33.3762469429204,
        33.8296009553368, 34.2891129357514, 34.7548665286645, 35.226946514731, 35.7054388261926,
        36.1904305625201, 36.6820100062681, 37.1802666391448, 37.6852911583004, 38.1971754928367,
        38.7160128205406, 39.2418975848454, 39.7749255120221, 40.3151936286044, 40.8628002790504,
        41.4178451436441, 41.9804292566399, 42.5506550246541, 43.1286262453058, 43.7144481261109,
        44.308227303633, 44.9100718628943, 45.5200913570505, 46.1383968273322, 46.7651008232578,
        47.4003174231212, 48.0441622547565, 48.6967525165863, 49.3582069989551, 50.0286461057523,
        50.7081918763296, 51.3969680077152, 52.0950998771306, 52.8027145648132, 53.5199408771477,
        54.2469093701133, 54.9837523730485, 55.7306040127389, 56.487600237832, 57.2548788435838,
        58.032579496942, 58.8208437619687, 59.6198151256098, 60.4296390238133, 61.2504628680032,
        62.0824360719126, 62.925710078781, 63.7804383889218, 64.6467765876637, 65.5248823736715,
        66.414915587652, 67.3170382414498, 68.2314145475379, 69.158210948909, 70.0975961493735,
        71.0497411442679, 72.0148192515813, 72.9930061435042, 73.9844798784057, 74.9894209332456,
        76.0410993721622, 77.1075269253553, 78.1889104396747, 79.2854596628643, 80.397387284245,
        81.5249089759691, 82.6682434348521, 83.8276124247918, 85.0032408197821, 86.1953566475303,
        87.4041911336857, 88.6299787466887, 89.8729572432489, 91.1333677144609, 92.4114546325674,
        93.7074658983764, 95.0216528893462, 96.354270508342, 97.7055772330781, 99.0758351662524,
        100.465310086385, 101.874271499368, 103.302992690743, 104.751750778702, 106.220826767844,
        107.710505603677, 109.221076227886, 110.752831634377, 112.306068926108, 113.881089372715,
        115.478198468946, 117.097705993918, 118.739926071201, 120.405177229745, 122.093782465665,
        123.806069304887, 125.54236986668, 127.303020928072, 129.088363989171, 130.898745339406,
        132.73451612469, 134.596032415536, 136.483655276115, 138.39775083429, 140.338690352632,
        142.306850300434, 144.302612426725, 146.326363834322, 148.378497054908, 150.459410125171,
        152.56950666401, 154.709195950816, 156.878893004864, 159.079018665804, 161.309999675296,
        163.572268759773, 165.866264714382, 168.192432488087, 170.551223269978, 172.943094576779,
        175.368510341595, 177.827941003892, 180.205993340766, 182.615846827026, 185.057926732162,
        187.532664012693, 190.040495388223, 192.581863418508, 195.157216581551, 197.767009352752,
        200.411702285105, 203.091762090474, 205.807661721952, 208.559880457329, 211.348903983665,
        214.175224483001, 217.039340719218, 219.941758126051, 222.882988896283, 225.863552072138,
        228.883973636868, 231.944786607582, 235.046531129304, 238.189754570292, 241.375011618637,
        244.602864380145, 247.873882477534, 251.188643150958, 254.54773135987, 257.951739886252,
        261.401269439225, 264.896928761053, 268.439334734573, 272.029112492054, 275.666895525515,
        279.353325798522, 283.089053859469, 286.874738956386, 290.711049153277, 294.598661448011,
        298.538261891796, 302.530545710246, 306.576217426066, 310.675990983383, 314.830589873737,
        319.040747263751, 323.307206124521, 327.630719362723, 332.012049953483, 336.451971075017,
        340.951266245078, 345.510729459222, 350.131165330925, 354.813389233576, 359.558227444364,
        364.366517290094, 369.239107294954, 374.176857330246, 379.180638766139, 384.251334625432,
        389.389839739389, 394.597060905645, 399.873917048233, 405.221339379748, 410.640271565675,
        416.131669890924, 421.696503428583, 427.424463261162, 433.23022673588, 439.114850669981,
        445.0794062356, 451.124979154745, 457.252669896931, 463.463593879499, 469.758881670649,
        476.139679195243, 482.607147943392, 489.16246518188, 495.806824168466, 502.541434369086,
        509.367521678014, 516.286328641009, 523.299114681495, 530.407156329812, 537.611747455583,
        544.914199503232, 552.31584173071, 559.818021451455, 567.422104279643, 575.12947437877,
        582.941534713608, 590.859707305587, 598.885433491646, 607.020174186593, 615.265410149038,
        623.62264225093, 632.093391750767, 640.6792005705, 649.381631576212, 658.202268862605,
        667.14271804135, 676.204606533356, 685.389583865009, 694.699321968427, 704.135515485809,
        713.699882077904, 723.394162736675, 733.220122102212, 743.179548783947, 753.27425568623,
        763.506080338335, 773.876885228941, 784.388558145157, 795.043012516158, 805.842187761482,
        816.788049644062, 827.882590628049, 839.127830241501, 850.525815443997, 862.078620999238,
        873.788349852718, 885.657133514521, 897.687132447314, 909.880536459622, 922.239565104424,
        934.766468083188, 947.463525655376, 960.333049053517, 973.377380903921, 986.598895653102,
        1000,
    ],
    csColor: [
        'rgb(0,0,190)',
        'rgb(0,2,191)',
        'rgb(0,4,192)',
        'rgb(0,7,193)',
        'rgb(0,9,194)',
        'rgb(0,11,194)',
        'rgb(0,13,195)',
        'rgb(0,15,196)',
        'rgb(0,18,197)',
        'rgb(0,20,198)',
        'rgb(0,22,199)',
        'rgb(0,24,200)',
        'rgb(0,26,201)',
        'rgb(0,29,201)',
        'rgb(0,31,202)',
        'rgb(0,33,203)',
        'rgb(0,35,204)',
        'rgb(0,37,205)',
        'rgb(0,40,206)',
        'rgb(0,42,207)',
        'rgb(0,44,208)',
        'rgb(0,46,208)',
        'rgb(0,48,209)',
        'rgb(0,51,210)',
        'rgb(0,53,211)',
        'rgb(0,55,212)',
        'rgb(0,57,213)',
        'rgb(0,59,214)',
        'rgb(0,62,215)',
        'rgb(0,64,216)',
        'rgb(0,66,216)',
        'rgb(0,68,217)',
        'rgb(0,70,218)',
        'rgb(0,73,219)',
        'rgb(0,75,220)',
        'rgb(0,77,220)',
        'rgb(0,79,221)',
        'rgb(0,82,221)',
        'rgb(0,84,222)',
        'rgb(0,86,222)',
        'rgb(0,88,223)',
        'rgb(0,90,223)',
        'rgb(0,93,224)',
        'rgb(0,95,224)',
        'rgb(0,97,224)',
        'rgb(0,99,225)',
        'rgb(0,101,225)',
        'rgb(0,104,226)',
        'rgb(0,106,226)',
        'rgb(0,108,227)',
        'rgb(0,110,227)',
        'rgb(0,112,227)',
        'rgb(0,115,228)',
        'rgb(0,117,228)',
        'rgb(0,119,229)',
        'rgb(0,121,229)',
        'rgb(0,123,230)',
        'rgb(0,126,230)',
        'rgb(0,128,231)',
        'rgb(0,130,231)',
        'rgb(0,132,231)',
        'rgb(0,134,232)',
        'rgb(0,137,232)',
        'rgb(0,139,233)',
        'rgb(0,141,233)',
        'rgb(0,143,234)',
        'rgb(0,145,234)',
        'rgb(0,148,235)',
        'rgb(0,150,235)',
        'rgb(0,151,236)',
        'rgb(0,153,236)',
        'rgb(0,154,237)',
        'rgb(0,156,237)',
        'rgb(0,157,238)',
        'rgb(0,159,239)',
        'rgb(0,160,239)',
        'rgb(0,162,240)',
        'rgb(0,163,240)',
        'rgb(0,165,241)',
        'rgb(0,166,241)',
        'rgb(0,168,242)',
        'rgb(0,169,243)',
        'rgb(0,171,243)',
        'rgb(0,172,244)',
        'rgb(0,173,244)',
        'rgb(0,175,245)',
        'rgb(0,176,246)',
        'rgb(0,178,246)',
        'rgb(0,179,247)',
        'rgb(0,181,247)',
        'rgb(0,182,248)',
        'rgb(0,184,249)',
        'rgb(0,185,249)',
        'rgb(0,187,250)',
        'rgb(0,188,250)',
        'rgb(0,190,251)',
        'rgb(0,191,251)',
        'rgb(0,193,252)',
        'rgb(0,194,253)',
        'rgb(0,195,253)',
        'rgb(0,197,254)',
        'rgb(0,198,254)',
        'rgb(0,200,255)',
        'rgb(2,201,255)',
        'rgb(5,202,255)',
        'rgb(7,204,255)',
        'rgb(9,205,255)',
        'rgb(12,206,255)',
        'rgb(14,207,255)',
        'rgb(16,208,255)',
        'rgb(19,209,255)',
        'rgb(21,211,255)',
        'rgb(23,212,255)',
        'rgb(26,213,255)',
        'rgb(28,214,255)',
        'rgb(31,215,255)',
        'rgb(33,216,255)',
        'rgb(35,218,255)',
        'rgb(38,219,255)',
        'rgb(40,220,255)',
        'rgb(42,221,255)',
        'rgb(45,222,255)',
        'rgb(47,223,255)',
        'rgb(49,225,255)',
        'rgb(52,226,255)',
        'rgb(54,227,255)',
        'rgb(56,228,255)',
        'rgb(59,229,255)',
        'rgb(61,231,255)',
        'rgb(63,232,255)',
        'rgb(66,233,255)',
        'rgb(68,234,255)',
        'rgb(70,235,255)',
        'rgb(73,236,255)',
        'rgb(75,238,255)',
        'rgb(77,239,255)',
        'rgb(80,240,255)',
        'rgb(79,239,248)',
        'rgb(77,238,240)',
        'rgb(76,237,233)',
        'rgb(74,236,225)',
        'rgb(73,236,218)',
        'rgb(71,235,210)',
        'rgb(70,234,203)',
        'rgb(68,233,195)',
        'rgb(67,232,188)',
        'rgb(65,231,180)',
        'rgb(64,230,173)',
        'rgb(62,229,165)',
        'rgb(61,229,158)',
        'rgb(59,228,150)',
        'rgb(58,227,143)',
        'rgb(57,226,135)',
        'rgb(55,225,128)',
        'rgb(54,224,120)',
        'rgb(52,223,113)',
        'rgb(51,222,105)',
        'rgb(49,222,98)',
        'rgb(48,221,90)',
        'rgb(46,220,83)',
        'rgb(45,219,75)',
        'rgb(43,218,68)',
        'rgb(42,217,60)',
        'rgb(40,216,53)',
        'rgb(39,215,45)',
        'rgb(37,214,38)',
        'rgb(36,214,30)',
        'rgb(35,213,23)',
        'rgb(33,212,15)',
        'rgb(32,211,8)',
        'rgb(30,210,0)',
        'rgb(34,211,1)',
        'rgb(39,213,2)',
        'rgb(43,214,3)',
        'rgb(48,215,4)',
        'rgb(52,217,4)',
        'rgb(56,218,5)',
        'rgb(61,219,6)',
        'rgb(65,221,7)',
        'rgb(70,222,8)',
        'rgb(74,223,9)',
        'rgb(78,225,10)',
        'rgb(83,226,11)',
        'rgb(87,227,11)',
        'rgb(92,228,12)',
        'rgb(96,230,13)',
        'rgb(100,231,14)',
        'rgb(105,232,15)',
        'rgb(109,234,16)',
        'rgb(114,235,17)',
        'rgb(118,236,18)',
        'rgb(122,238,18)',
        'rgb(127,239,19)',
        'rgb(131,240,20)',
        'rgb(136,242,21)',
        'rgb(140,243,22)',
        'rgb(144,244,23)',
        'rgb(149,246,24)',
        'rgb(153,247,25)',
        'rgb(158,248,26)',
        'rgb(162,250,26)',
        'rgb(166,251,27)',
        'rgb(171,252,28)',
        'rgb(175,254,29)',
        'rgb(180,255,30)',
        'rgb(182,255,29)',
        'rgb(184,255,28)',
        'rgb(187,255,27)',
        'rgb(189,255,26)',
        'rgb(191,255,26)',
        'rgb(193,255,25)',
        'rgb(195,255,24)',
        'rgb(198,255,23)',
        'rgb(200,255,22)',
        'rgb(202,255,21)',
        'rgb(204,255,20)',
        'rgb(206,255,19)',
        'rgb(209,255,19)',
        'rgb(211,255,18)',
        'rgb(213,255,17)',
        'rgb(215,255,16)',
        'rgb(217,255,15)',
        'rgb(220,255,14)',
        'rgb(222,255,13)',
        'rgb(224,255,12)',
        'rgb(226,255,12)',
        'rgb(228,255,11)',
        'rgb(231,255,10)',
        'rgb(233,255,9)',
        'rgb(235,255,8)',
        'rgb(237,255,7)',
        'rgb(239,255,6)',
        'rgb(242,255,5)',
        'rgb(244,255,4)',
        'rgb(246,255,4)',
        'rgb(248,255,3)',
        'rgb(250,255,2)',
        'rgb(253,255,1)',
        'rgb(255,255,0)',
        'rgb(255,253,0)',
        'rgb(255,251,0)',
        'rgb(255,250,0)',
        'rgb(255,248,0)',
        'rgb(255,246,0)',
        'rgb(255,244,0)',
        'rgb(255,243,0)',
        'rgb(255,241,0)',
        'rgb(255,239,0)',
        'rgb(255,237,0)',
        'rgb(255,236,0)',
        'rgb(255,234,0)',
        'rgb(255,232,0)',
        'rgb(255,230,0)',
        'rgb(255,229,0)',
        'rgb(255,227,0)',
        'rgb(255,225,0)',
        'rgb(255,223,0)',
        'rgb(255,222,0)',
        'rgb(255,220,0)',
        'rgb(255,218,0)',
        'rgb(255,216,0)',
        'rgb(255,214,0)',
        'rgb(255,213,0)',
        'rgb(255,211,0)',
        'rgb(255,209,0)',
        'rgb(255,207,0)',
        'rgb(255,206,0)',
        'rgb(255,204,0)',
        'rgb(255,202,0)',
        'rgb(255,200,0)',
        'rgb(255,199,0)',
        'rgb(255,197,0)',
        'rgb(255,195,0)',
        'rgb(255,195,0)',
        'rgb(255,193,0)',
        'rgb(255,190,0)',
        'rgb(255,188,0)',
        'rgb(255,186,0)',
        'rgb(255,183,0)',
        'rgb(255,181,0)',
        'rgb(255,179,0)',
        'rgb(255,176,0)',
        'rgb(255,174,0)',
        'rgb(255,172,0)',
        'rgb(255,169,0)',
        'rgb(255,167,0)',
        'rgb(255,164,0)',
        'rgb(255,162,0)',
        'rgb(255,160,0)',
        'rgb(255,157,0)',
        'rgb(255,155,0)',
        'rgb(255,153,0)',
        'rgb(255,150,0)',
        'rgb(255,148,0)',
        'rgb(255,146,0)',
        'rgb(255,143,0)',
        'rgb(255,141,0)',
        'rgb(255,139,0)',
        'rgb(255,136,0)',
        'rgb(255,134,0)',
        'rgb(255,132,0)',
        'rgb(255,129,0)',
        'rgb(255,127,0)',
        'rgb(255,125,0)',
        'rgb(255,122,0)',
        'rgb(255,120,0)',
        'rgb(255,118,0)',
        'rgb(255,115,0)',
        'rgb(255,112,0)',
        'rgb(255,108,0)',
        'rgb(255,105,0)',
        'rgb(255,101,0)',
        'rgb(255,98,0)',
        'rgb(255,95,0)',
        'rgb(255,91,0)',
        'rgb(255,88,0)',
        'rgb(255,85,0)',
        'rgb(255,81,0)',
        'rgb(255,78,0)',
        'rgb(255,74,0)',
        'rgb(255,71,0)',
        'rgb(255,68,0)',
        'rgb(255,64,0)',
        'rgb(255,61,0)',
        'rgb(255,58,0)',
        'rgb(255,54,0)',
        'rgb(255,51,0)',
        'rgb(255,47,0)',
        'rgb(255,44,0)',
        'rgb(255,41,0)',
        'rgb(255,37,0)',
        'rgb(255,34,0)',
        'rgb(255,31,0)',
        'rgb(255,27,0)',
        'rgb(255,24,0)',
        'rgb(255,20,0)',
        'rgb(255,17,0)',
        'rgb(255,14,0)',
        'rgb(255,10,0)',
        'rgb(255,7,0)',
        'rgb(255,4,0)',
        'rgb(255,0,0)',
        'rgb(255,0,4)',
        'rgb(255,0,7)',
        'rgb(255,0,11)',
        'rgb(255,0,14)',
        'rgb(255,0,18)',
        'rgb(255,0,21)',
        'rgb(255,0,25)',
        'rgb(255,0,28)',
        'rgb(255,0,32)',
        'rgb(255,0,35)',
        'rgb(255,0,39)',
        'rgb(255,0,42)',
        'rgb(255,0,46)',
        'rgb(255,0,49)',
        'rgb(255,0,53)',
        'rgb(255,0,56)',
        'rgb(255,0,60)',
        'rgb(255,0,63)',
        'rgb(255,0,67)',
        'rgb(255,0,70)',
        'rgb(255,0,74)',
        'rgb(255,0,77)',
        'rgb(255,0,81)',
        'rgb(255,0,85)',
        'rgb(255,0,88)',
        'rgb(255,0,92)',
        'rgb(255,0,95)',
        'rgb(255,0,99)',
        'rgb(255,0,102)',
        'rgb(255,0,106)',
        'rgb(255,0,109)',
        'rgb(255,0,113)',
        'rgb(255,0,116)',
        'rgb(255,0,120)',
        'rgb(252,1,122)',
        'rgb(248,2,124)',
        'rgb(245,4,125)',
        'rgb(241,5,127)',
        'rgb(238,6,129)',
        'rgb(235,7,131)',
        'rgb(231,8,132)',
        'rgb(228,9,134)',
        'rgb(225,11,136)',
        'rgb(221,12,138)',
        'rgb(218,13,139)',
        'rgb(214,14,141)',
        'rgb(211,15,143)',
        'rgb(208,16,145)',
        'rgb(204,18,146)',
        'rgb(201,19,148)',
        'rgb(198,20,150)',
        'rgb(194,21,152)',
        'rgb(191,22,153)',
        'rgb(187,23,155)',
        'rgb(184,25,157)',
        'rgb(181,26,159)',
        'rgb(177,27,161)',
        'rgb(174,28,162)',
        'rgb(171,29,164)',
        'rgb(167,31,166)',
        'rgb(164,32,168)',
        'rgb(160,33,169)',
        'rgb(157,34,171)',
        'rgb(154,35,173)',
        'rgb(150,36,175)',
        'rgb(147,38,176)',
        'rgb(144,39,178)',
        'rgb(140,40,180)',
        'rgb(141,41,181)',
        'rgb(141,42,182)',
        'rgb(142,43,184)',
        'rgb(143,44,185)',
        'rgb(144,44,186)',
        'rgb(144,45,187)',
        'rgb(145,46,188)',
        'rgb(146,47,189)',
        'rgb(147,48,191)',
        'rgb(147,49,192)',
        'rgb(148,50,193)',
        'rgb(149,51,194)',
        'rgb(150,51,195)',
        'rgb(150,52,196)',
        'rgb(151,53,198)',
        'rgb(152,54,199)',
        'rgb(152,55,200)',
        'rgb(153,56,201)',
        'rgb(154,57,202)',
        'rgb(155,58,203)',
        'rgb(155,58,205)',
        'rgb(156,59,206)',
        'rgb(157,60,207)',
        'rgb(158,61,208)',
        'rgb(158,62,209)',
        'rgb(159,63,211)',
        'rgb(160,64,212)',
        'rgb(161,65,213)',
        'rgb(161,66,214)',
        'rgb(162,66,215)',
        'rgb(163,67,216)',
        'rgb(163,68,218)',
        'rgb(164,69,219)',
        'rgb(165,70,220)',
        'rgb(166,72,221)',
        'rgb(167,74,221)',
        'rgb(168,75,222)',
        'rgb(169,77,222)',
        'rgb(169,79,223)',
        'rgb(170,81,224)',
        'rgb(171,82,224)',
        'rgb(172,84,225)',
        'rgb(173,86,225)',
        'rgb(174,88,226)',
        'rgb(175,89,226)',
        'rgb(176,91,227)',
        'rgb(176,93,228)',
        'rgb(177,95,228)',
        'rgb(178,96,229)',
        'rgb(179,98,229)',
        'rgb(180,100,230)',
        'rgb(181,102,231)',
        'rgb(182,103,231)',
        'rgb(183,105,232)',
        'rgb(183,107,232)',
        'rgb(184,109,233)',
        'rgb(185,111,234)',
        'rgb(186,112,234)',
        'rgb(187,114,235)',
        'rgb(188,116,235)',
        'rgb(189,118,236)',
        'rgb(190,119,236)',
        'rgb(191,121,237)',
        'rgb(191,123,238)',
        'rgb(192,125,238)',
        'rgb(193,126,239)',
        'rgb(194,128,239)',
        'rgb(195,130,240)',
        'rgb(196,131,240)',
        'rgb(197,131,240)',
        'rgb(198,132,240)',
        'rgb(199,133,240)',
        'rgb(200,134,240)',
        'rgb(201,134,240)',
        'rgb(202,135,240)',
        'rgb(203,136,240)',
        'rgb(204,137,240)',
        'rgb(205,137,240)',
        'rgb(206,138,240)',
        'rgb(207,139,240)',
        'rgb(208,140,240)',
        'rgb(209,140,240)',
        'rgb(210,141,240)',
        'rgb(211,142,240)',
        'rgb(212,142,240)',
        'rgb(213,143,240)',
        'rgb(215,144,240)',
        'rgb(216,145,240)',
        'rgb(217,145,240)',
        'rgb(218,146,240)',
        'rgb(219,147,240)',
        'rgb(220,148,240)',
        'rgb(221,148,240)',
        'rgb(222,149,240)',
        'rgb(223,150,240)',
        'rgb(224,151,240)',
        'rgb(225,151,240)',
        'rgb(226,152,240)',
        'rgb(227,153,240)',
        'rgb(228,153,240)',
        'rgb(229,154,240)',
        'rgb(230,155,240)',
    ],
};

export const ResChangeCS: ColorscaleDef = {
    csData: [-10.0, -5.0, -2.0, -1.0, 1.0, 2.0, 5.0, 10.0],
    csColor: [
        'rgb(40,50,194)',
        'rgb(106,90,205)',
        'rgb(0,191,255)',
        'rgb(117,124,136)',
        'rgb(117,124,136)',
        'rgb(227,36,43)',
        'rgb(185,14,10)',
        'rgb(122,23,18)',
    ],
};
