import { ResisitivityCS, ResChangeCS } from '../../components/Helper/ConstDefinition';
import { ModelBars, OneBar, BarChartOptions } from '../../components/Helper/datatypes';

let { csData } = ResisitivityCS;
let csBackgroundColor = ResisitivityCS.csColor;

export function setColorscale(csValue: string) {
    if (csValue === 'Resistivity') {
        csData = ResisitivityCS.csData;
        csBackgroundColor = ResisitivityCS.csColor;
    } else {
        csData = ResChangeCS.csData;
        csBackgroundColor = ResChangeCS.csColor;
    }
}

export function valueToColor(dataValue: number) {
    const arrayLength = csData.length;
    let leftBound: number;
    let rightBound: number;
    const firstValueInArray: number = 0;
    const minValue: number = csData[0];
    const maxValue: number = csData[arrayLength - 1];

    let colorValue: string;

    if (Number.isNaN(dataValue)) {
        // Return white if value is NAN
        return 'rgb(255,255,255)';
    }

    if (dataValue <= minValue) {
        return csBackgroundColor[firstValueInArray];
    }

    if (dataValue >= maxValue) {
        return csBackgroundColor[arrayLength - 1];
    }

    // binary search
    leftBound = 0;
    rightBound = arrayLength - 1;
    let middleIndex: number;
    while (rightBound - leftBound > 5) {
        middleIndex = Math.floor((leftBound + rightBound) / 2); // "div" should be faster than "/" - and floating point precision is not needed here
        if (dataValue > csData[middleIndex]) {
            leftBound = middleIndex;
        } else {
            rightBound = middleIndex;
        }
    }
    // incremental search
    colorValue = csBackgroundColor[rightBound];
    for (let i = rightBound; i >= leftBound; i -= 1) {
        if (dataValue <= csData[i]) {
            colorValue = csBackgroundColor[i];
        } else {
            break;
        }
    }

    return colorValue;
}

export function getCSChartData() {
    const barLayerNo: number = csData.length;

    const localLabels: string[] = [];
    const localDataset: OneBar[] = [];

    const newChannel: OneBar = {
        label: 'CS',
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 0,
        barPercentage: 100, // adjust this value as needed
        categoryPercentage: 0.5, // adjust this value as needed
    };

    localDataset.push(newChannel);

    for (let i = 0; i < barLayerNo; i += 1) {
        localDataset[0].data.push(1);

        localDataset[0].backgroundColor.push(csBackgroundColor[i]);

        localDataset[0].borderColor.push('black');

        localLabels.push(csData[i].toString());
    }

    const dataForPlot: ModelBars = {
        labels: localLabels,
        datasets: localDataset,
    };

    return dataForPlot;
}

export function getCSChartDataDiscrete() {
    const barLayerNo: number = csData.length;

    const localLabels: string[] = [];
    const localDataset: OneBar[] = [];

    let newChannel: OneBar;

    for (let i = 0; i < barLayerNo; i += 1) {
        if (csData[i] > 0) {
            newChannel = {
                label: 'CS',
                data: [],
                backgroundColor: [],
                borderColor: [],
                borderWidth: 0,
                barPercentage: 100, // adjust this value as needed
                categoryPercentage: 0.5, // adjust this value as needed
            };

            newChannel.data.push(csData[i]);

            newChannel.backgroundColor.push(csBackgroundColor[i]);

            newChannel.borderColor.push('black');

            localDataset.push(newChannel);
        }
    }

    for (let i = barLayerNo - 1; i >= 0; i -= 1) {
        if (csData[i] < 0) {
            newChannel = {
                label: 'CS',
                data: [],
                backgroundColor: [],
                borderColor: [],
                borderWidth: 0,
                barPercentage: 100, // adjust this value as needed
                categoryPercentage: 0.5, // adjust this value as needed
            };

            newChannel.data.push(csData[i]);

            newChannel.backgroundColor.push(csBackgroundColor[i]);

            newChannel.borderColor.push('black');

            localDataset.push(newChannel);
        }
    }

    localLabels.push('1');

    const dataForPlot: ModelBars = {
        labels: localLabels,
        datasets: localDataset,
    };
    return dataForPlot;
}

export function getChartCSBarOptions(
    xCSType: string,
    xCSAxisText: string,
    indexAxis: string,
    maxTicksLimit: number,
) {
    const options: BarChartOptions = {
        plugins: {
            title: {
                display: true,
                text: '',
            },
        },
        indexAxis,
        scales: {
            x: {
                type: xCSType,
                // min: '-10.00',
                title: {
                    display: true,
                    text: xCSAxisText,
                },
                time: {
                    displayFormats: {
                        day: 'dd-MM-yy',
                    },
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit,
                    maxRotation: 0,
                    minRotation: 0,
                    align: 'center',
                },
            },
            y: {
                type: 'linear' as const,
                stacked: true,
                beginAtZero: false,
                reverse: false,
                display: false,
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10,
                    maxRotation: 0,
                    minRotation: 0,
                    align: 'center',
                    display: false,
                },
            },
        },
        maintainAspectRatio: false,
    };

    return options;
}
