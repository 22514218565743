import temImage from '../../assets/tTEM_01.jpg';
import classes from './Header.module.css';

const Header = () => (
    <div>
        {/* <header className={classes.header}>
            <h1>SuperTEM - Groundwater Monitoring</h1>
        </header> */}
        <div className={classes['main-image']}>
            <img src={temImage} alt="SuperTEM - Groundwater Monitoring" />
        </div>
    </div>
);

export default Header;
